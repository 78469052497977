var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, width: "500", persistent: "" } },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("v-icon", { attrs: { color: "#000", left: "" } }, [
                _vm._v("mdi-information-variant")
              ]),
              _vm._t("title", [_vm._v(_vm._s(_vm.attr.name) + " Info")]),
              _c("v-spacer")
            ],
            2
          ),
          _c(
            "v-card-text",
            [
              _c("br"),
              _vm._t("body", [
                _vm._v(
                  " " +
                    _vm._s(_vm.attr.info || "No information available") +
                    " "
                )
              ])
            ],
            2
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }