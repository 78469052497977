<template>
  <v-dialog :value="value" width="500" persistent>
    <v-card flat>
      <v-card-title>
        <v-icon color="#000" left>mdi-information-variant</v-icon>
        <slot name="title">{{attr.name}} Info</slot>
        <v-spacer />
      </v-card-title>
      <v-card-text><br>
        <slot name="body">
          {{ attr.info || 'No information available'}}
        </slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('update:value', false)">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'AttrInfo',

  data: () => ({
  }),

  props: [
    'value',
    'attr',
    'attrvalue'
  ]
}
</script>
